<template>
  <b-overlay :show="loading" rounded="lg" opacity="1">
    <v-card v-if="rows.length > 0" max-width="450" class="mx-auto" flat>
      <v-list three-line>
        <template v-for="(item, index) in rows">
          <v-list-item class="pr-0" :key="item.Id">
            <v-list-item-content class="pr-2">
              <div>
                <span class="font-weight-bold">{{ item.Notification.Subject }}</span>
              </div>
              <div>
                <p v-if="item.Notification.Link == null" class="mt-3 font-weight-light text-break"
                   v-html="item.Notification.Body"></p>
                <a v-else class="mt-3 font-weight-light text-break" v-html="item.Notification.Body"
                   @click="openLink(item.Notification.Link)"></a>
              </div>
              <div class="text-left text-muted">
                <span class="mt-5 font-size-sm">{{ item.Notification.PlannedSendDate | ago }}</span>
              </div>
            </v-list-item-content>
            <v-list-item-action v-if="item.Notification.Path != null" class="mr-4 align-self-start">
              <v-btn
                  icon
                  text
                  small
                  @click="goPath(item.Notification.Path)"
              >
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider
              class="my-0 mx-4"
              v-if="index != rows.length - 1"
              :key="index"
          ></v-divider>
        </template>
      </v-list>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="text-primary" @click="goMyNotifications()" text>Tümünü gör</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else max-width="450" class="mx-auto" flat style="background-color: transparent">
      <v-card-text class="text-center"> Bildirim yok</v-card-text>
    </v-card>
  </b-overlay>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment'
import {CHANGE_TOKEN} from "@/core/services/store/auth.module";

export default {
  name: 'notification-top-bar',
  data() {
    return {
      loading: false,
      loadingSave: false,
      rows: []
    }
  },
  filters: {
    momentDateTime: function (date) {
      return moment(date).format('DD.MM.YYYY HH:hh')
    },
    ago: function (date) {
      return moment(date).locale('tr').fromNow()
    }
  },
  async mounted() {
    this.getDataFromApi()
  },
  methods: {
    readNotification(item) {
      this.loadingSave = true
      ApiService.setHeader()
      ApiService.put('api/MyNotificationUser/read', {Id: item.Id, IsRead: !item.IsRead})
          .then(() => {
            this.loadingSave = false
            this.getDataFromApi()
          })
          .catch(({response}) => {
            this.loadingSave = false
            ApiService.showError(response)
          })
    },

    goMyNotifications() {
      if (this.$router.history.current.name !== 'bildirimler')
        this.$router.push({name: 'bildirimler'})
      this.$emit('closeTopBar')
    },

    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/MyNotificationUser/pushnotification', {
        sortBy: ['Id'],
        descending: [false],
        rowsPerPage: 3,
        page: 1
      })
          .then((data) => {
            this.$nextTick(() => {
              this.rows = data.data
              this.loading = false

              for (let i = 0; i < this.rows.length; i++) {
                const element = this.rows[i].Notification
                if (element.Data) element.Data = JSON.parse(element.Data)
              }
              if (this.rows.filter((s) => !s.IsRead).length) this.$emit('shine')
              else this.$emit('dontShine')
            })
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },

    openLink(link) {
      if (link) {
        window.open(link, '_blank')
      }
    },

    goPath(path) {
      if (path !== this.$route.path) {
        if (path.includes('studentId'))
          this.changeUser(path.split('studentId=')[1])
        else
          this.$router.push({path: path})
      }

      this.$emit('closeTopBar')
    },

    changeUser(id) {
      ApiService.setHeader()
      ApiService.post('api/customerproxyuser', {
        UserId: id,
        Type: 'Student'
      })
          .then(({data}) => {
            this.$store.dispatch(CHANGE_TOKEN, data)
            window.location.reload()
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },
  }
}
</script>
