<template>
  <div id="kt_footer" class="footer py-2 py-lg-0 my-5 d-flex flex-lg-column">
    <div
        class="d-flex flex-column flex-md-row align-items-center justify-content-center"
        v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div v-if="!$vuetify.breakpoint.mobile">
        <a
            v-if="!loading"
            data-v-1940ce3b=""
            class="mr-5"
            @click="loginPdevco"
            style="max-height: 60px"
        ><img
            src="/media/logos/pdevco-logo.png"
            class="w-32 md:w-auto"
            style="max-width: 190px;"
            data-v-1940ce3b=""
        /></a>
        <v-progress-circular v-else color="success" class="mr-5" indeterminate></v-progress-circular>
        <a
            href="https://apps.apple.com/tr/app/metaceviz/id1645521965"
            target="_blank"
            data-v-1940ce3b=""
            style="max-height: 60px"
        ><img
            src="/media/logos/app-store.png"
            style="max-width: 190px;"
        /></a>
        <a
            class="mx-2"
            href="https://play.google.com/store/apps/details?id=com.cp.metaceviz"
            target="_blank"
            data-v-1940ce3b=""
        ><img
            src="/media/logos/google-play.png"
            style="max-width: 190px;"
        /></a>
        <div></div>
      </div>
      <!--      <div class="mb-4">-->
      <!--        <bruit-io-->
      <!--            v-b-tooltip.hover.top="'Geri Bildirim Gönder'"-->
      <!--            v-bind:config.prop="bruitConfig"-->
      <!--        ></bruit-io>-->
      <!--      </div>-->
    </div>
    <v-bottom-navigation
        color="#8950FC"
        :max-height="50 + bottomSafeArea"
        app
        fixed
        grow
        v-if="$vuetify.breakpoint.mobile"
        :value="pageId"
    >
      <v-btn
          :class="{ 'disable-events': currentPage === 'dashboard', 'my-btn': isSmallScreen }"
          @click="gotoMainPage"
      >
        <span>Anasayfa</span>
        <v-icon>mdi-home-variant-outline</v-icon>
      </v-btn>

      <v-btn
          v-if="!isCustomer || isProxy"
          :class="{ 'disable-events': currentPage === 'odevlerim', 'my-btn': isSmallScreen }"
          @click="gotoHomeworks"
      >
        <span>Ödevler</span>
        <v-icon>mdi-book-edit-outline</v-icon>
      </v-btn>

      <v-btn
          v-if="isCustomer && !isProxy"
          :class="{ 'disable-events': currentPage === 'bildirimler', 'my-btn': isSmallScreen }"
          @click="gotoNotifications"
      >
        <span>Bildirimler</span>
        <v-icon>mdi-bell-outline</v-icon>
      </v-btn>

      <StudentChanger v-if="isCustomer"/>

      <v-btn
          v-if="isCustomer && !isProxy"
          :class="{ 'disable-events': currentPage === 'formlar', 'my-btn': isSmallScreen }"
          @click="gotoForms"
      >
        <span>Formlar</span>
        <v-icon>mdi-checkbox-multiple-marked-outline</v-icon>
      </v-btn>

      <v-btn
          v-if="isCustomer && !isProxy"
          :class="{ 'disable-events': currentPage === 'ayarlar', 'my-btn': isSmallScreen }"
          @click="gotoSettings"
      >
        <span>Ayarlar</span>
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>

      <v-btn
          v-if="!isCustomer || isProxy"
          :class="{ 'disable-events': currentPage === 'ders-programi', 'my-btn': isSmallScreen }"
          @click="gotoLessonProgram"
      >
        <span>Ders Programı</span>
        <v-icon>mdi-calendar-clock-outline</v-icon>
      </v-btn>

      <v-btn
          v-if="!isCustomer || isProxy"
          :class="{ 'disable-events': currentPage === 'sinavlar', 'my-btn': isSmallScreen }"
          @click="gotoExams"
      >
        <span>Sınavlar</span>
        <v-icon>mdi-file-document-edit-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
// import bruitConfig from '@/bruit-config'
import {SafeArea} from 'capacitor-plugin-safe-area'
import StudentChanger from '@/view/components/StudentChanger.vue'
import {Device} from '@capacitor/device'
import ApiService from "@/core/services/api.service";

export default {
  props: ['isProxy', 'isCustomer'],
  data() {
    return {
      // bruitConfig,
      bottomSafeArea: 0,
      pageId: 0,
      loading: false
    }
  },
  components: {StudentChanger},
  name: 'KTFooter',
  async created() {
    const device = await Device.getInfo()
    if (device.platform == 'ios') {
      SafeArea.getSafeAreaInsets().then(({insets}) => {
        this.bottomSafeArea = insets.bottom + 10
      })
    }
  },
  computed: {
    ...mapGetters(['layoutConfig']),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig('footer.width') === 'fluid'
    },
    currentPage() {
      return this.$route.name
    },
    isSmallScreen() {
      return window.innerWidth <= 350
    }
  },
  watch: {
    currentPage(val) {
      switch (val) {
        case 'dashboard':
          this.pageId = 0
          break
        case 'odevlerim':
          this.pageId = 1
          break
        case 'ders-programi':
          this.pageId = 2
          break
        case 'sinavlar':
          this.pageId = 3
          break
        case 'bildirimler':
          this.pageId = 1
          break
        case 'formlar':
          this.pageId = 3
          break
        case 'ayarlar':
          this.pageId = 4
          break
        default:
          this.pageId = 0
      }
    }
  },
  methods: {
    gotoMainPage() {
      this.$router.push({name: 'dashboard'})
    },
    gotoHomeworks() {
      this.$router.push({name: 'odevlerim'})
    },
    gotoLessonProgram() {
      this.$router.push({name: 'ders-programi'})
    },
    gotoExams() {
      this.$router.push({name: 'sinavlar'})
    },
    gotoNotifications() {
      this.$router.push({name: 'bildirimler'})
    },
    gotoForms() {
      this.$router.push({name: 'formlar'})
    },
    gotoSettings() {
      this.$router.push({name: 'ayarlar'})
    },
    loginPdevco() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post("api/App/pdevco-session-link", {})
          .then(({data}) => {
            if (data != null && data != "")
              window.open(data, "_blank");
            else
              ApiService.showErrorMessage("Pdevco üyeliğiniz bulunmuyor.")

            this.loading = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    }
  }
}
</script>
<style>
.disable-events {
  pointer-events: none;
}

.v-btn.my-btn {
  min-width: 20px !important;
}
</style>
