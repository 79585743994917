var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "kt_header_mobile",
      staticClass: "header-mobile header-mobile-fixed",
      class: _vm.headerClasses,
      style:
        "padding:" + _vm.topSafeArea + "px 0px " + _vm.topSafeArea / 2 + "px",
      attrs: { id: "kt_header_mobile" },
    },
    [
      _c("KTQuickActions", {
        staticClass: "ml-1",
        attrs: { isProxy: _vm.isProxy, isCustomer: _vm.isCustomer },
      }),
      _c(
        "div",
        { staticClass: "d-flex align-items-center" },
        [
          _c(
            "b-dropdown",
            {
              ref: "notificationDropdown",
              attrs: {
                size: "sm",
                variant: "link",
                "toggle-class": "topbar-item text-decoration-none p-0",
                "no-caret": "",
                "no-flip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { class: _vm.bellClass },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "p-0", style: _vm.bellStyle },
                            [_vm._v(_vm._s(_vm.bellKind))]
                          ),
                          _c("span", { staticClass: "pulse-ring" }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "b-dropdown-text",
                { staticClass: "min-w-md-450px", attrs: { tag: "div" } },
                [
                  _c("NotificationTopBar", {
                    on: {
                      shine: _vm.shineMethod,
                      dontShine: _vm.dontShineMethod,
                      closeTopBar: _vm.closeTopBar,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("KTQuickPanel", {
            attrs: { isProxy: _vm.isProxy, isCustomer: _vm.isCustomer },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }