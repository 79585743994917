var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "btn px-0 pt-1" },
    [
      !_vm.$vuetify.breakpoint.mobile
        ? _c(
            "v-btn",
            {
              staticClass: "mx-2 mt-2",
              attrs: { depressed: "" },
              on: { click: _vm.getData },
            },
            [
              _c("span", { staticClass: "text-break text-center" }, [
                _vm._v(_vm._s(_vm.name)),
              ]),
            ]
          )
        : _c(
            "v-btn",
            { attrs: { icon: "", "x-large": "" }, on: { click: _vm.getData } },
            [
              _c("span", [_vm._v("Öğrenciler")]),
              _c("v-icon", { attrs: { "x-large": "" } }, [
                _vm._v("mdi-account-group-outline"),
              ]),
            ],
            1
          ),
      _vm.isCustomer
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": _vm.$vuetify.breakpoint.mobile ? 340 : 400,
              },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "text-h5 grey lighten-2" },
                    [_vm._v(" Kullanıcı Listeniz")]
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center my-2" },
                        [
                          _c("div", { staticClass: "symbol symbol-50 mr-2" }, [
                            _c("span", { staticClass: "symbol-label" }, [
                              _c("img", {
                                staticClass: "h-120 align-self-end",
                                staticStyle: { width: "inherit" },
                                attrs: {
                                  src: "/media/users/blank.jpg",
                                  alt: "",
                                },
                              }),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column flex-grow-1" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "text-dark-75 text-hover-primary font-size-lg font-weight-bolder",
                                  attrs: { href: "#" },
                                },
                                [_vm._v(_vm._s(_vm.customerName))]
                              ),
                            ]
                          ),
                          _c("div", {}, [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isProxy,
                                    expression: "isProxy",
                                  },
                                ],
                                staticClass:
                                  "btn btn-light-primary btn-bold mb-1",
                                on: { click: _vm.removeProxyUser },
                              },
                              [_vm._v(" Asıl Kullanıcıya Dön ")]
                            ),
                          ]),
                        ]
                      ),
                      _vm._l(_vm.students, function (student) {
                        return [
                          _c(
                            "div",
                            {
                              key: student.Id,
                              staticClass: "d-flex align-items-center my-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "symbol symbol-50 mr-2" },
                                [
                                  _c("span", { staticClass: "symbol-label" }, [
                                    _c("img", {
                                      staticClass: "h-120 align-self-end",
                                      staticStyle: { width: "inherit" },
                                      attrs: {
                                        src: student.ImageKey
                                          ? student.FilePublicUrl
                                          : "/media/users/blank.jpg",
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex flex-column flex-grow-1",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "text-dark-75 text-hover-primary font-size-lg font-weight-bolder",
                                      attrs: { href: "#" },
                                    },
                                    [_vm._v(_vm._s(student.FullName))]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {},
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover.top",
                                          value: "Kullanıcıya Geçiş Yap",
                                          expression: "'Kullanıcıya Geçiş Yap'",
                                          modifiers: { hover: true, top: true },
                                        },
                                      ],
                                      staticClass: "mr-2",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.changeUser(student)
                                        },
                                      },
                                    },
                                    [_vm._v(" mdi-account-arrow-right ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = false
                            },
                          },
                        },
                        [_vm._v(" Kapat")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }