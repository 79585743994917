var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-loader page-loader-logo" }, [
    _c("img", {
      staticStyle: { "max-width": "180px" },
      attrs: { alt: "", src: _vm.logoImg, width: "160" },
    }),
    _c("div", {
      staticClass: "spinner",
      class: _vm.spinnerClass || "spinner-primary",
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }