var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column flex-root" },
    [
      _c("KTHeaderMobile", {
        attrs: { isProxy: _vm.isProxy, isCustomer: _vm.isCustomer },
      }),
      _vm.loaderEnabled ? _c("Loader") : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex flex-row flex-column-fluid page" },
        [
          _c("KTAside", {
            attrs: { isProxy: _vm.isProxy, isCustomer: _vm.isCustomer },
          }),
          _c(
            "div",
            {
              staticClass: "d-flex flex-column flex-row-fluid wrapper",
              attrs: { id: "kt_wrapper" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "content d-flex flex-column flex-column-fluid",
                  attrs: { id: "kt_content" },
                },
                [
                  _c("div", { staticClass: "d-flex flex-column-fluid" }, [
                    _c(
                      "div",
                      {
                        class: {
                          "container-fluid": _vm.contentFluid,
                          container: !_vm.contentFluid,
                        },
                      },
                      [
                        _c(
                          "transition",
                          { attrs: { name: "fade-in-up" } },
                          [
                            _c("router-view", {
                              attrs: {
                                isProxy: _vm.isProxy,
                                isCustomer: _vm.isCustomer,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c("KTFooter", {
                attrs: { isProxy: _vm.isProxy, isCustomer: _vm.isCustomer },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }