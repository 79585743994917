var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "footer py-2 py-lg-0 my-5 d-flex flex-lg-column",
      attrs: { id: "kt_footer" },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column flex-md-row align-items-center justify-content-center",
          class: {
            "container-fluid": _vm.widthFluid,
            container: !_vm.widthFluid,
          },
        },
        [
          !_vm.$vuetify.breakpoint.mobile
            ? _c(
                "div",
                [
                  !_vm.loading
                    ? _c(
                        "a",
                        {
                          staticClass: "mr-5",
                          staticStyle: { "max-height": "60px" },
                          attrs: { "data-v-1940ce3b": "" },
                          on: { click: _vm.loginPdevco },
                        },
                        [
                          _c("img", {
                            staticClass: "w-32 md:w-auto",
                            staticStyle: { "max-width": "190px" },
                            attrs: {
                              src: "/media/logos/pdevco-logo.png",
                              "data-v-1940ce3b": "",
                            },
                          }),
                        ]
                      )
                    : _c("v-progress-circular", {
                        staticClass: "mr-5",
                        attrs: { color: "success", indeterminate: "" },
                      }),
                  _vm._m(0),
                  _vm._m(1),
                  _c("div"),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm.$vuetify.breakpoint.mobile
        ? _c(
            "v-bottom-navigation",
            {
              attrs: {
                color: "#8950FC",
                "max-height": 50 + _vm.bottomSafeArea,
                app: "",
                fixed: "",
                grow: "",
                value: _vm.pageId,
              },
            },
            [
              _c(
                "v-btn",
                {
                  class: {
                    "disable-events": _vm.currentPage === "dashboard",
                    "my-btn": _vm.isSmallScreen,
                  },
                  on: { click: _vm.gotoMainPage },
                },
                [
                  _c("span", [_vm._v("Anasayfa")]),
                  _c("v-icon", [_vm._v("mdi-home-variant-outline")]),
                ],
                1
              ),
              !_vm.isCustomer || _vm.isProxy
                ? _c(
                    "v-btn",
                    {
                      class: {
                        "disable-events": _vm.currentPage === "odevlerim",
                        "my-btn": _vm.isSmallScreen,
                      },
                      on: { click: _vm.gotoHomeworks },
                    },
                    [
                      _c("span", [_vm._v("Ödevler")]),
                      _c("v-icon", [_vm._v("mdi-book-edit-outline")]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isCustomer && !_vm.isProxy
                ? _c(
                    "v-btn",
                    {
                      class: {
                        "disable-events": _vm.currentPage === "bildirimler",
                        "my-btn": _vm.isSmallScreen,
                      },
                      on: { click: _vm.gotoNotifications },
                    },
                    [
                      _c("span", [_vm._v("Bildirimler")]),
                      _c("v-icon", [_vm._v("mdi-bell-outline")]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isCustomer ? _c("StudentChanger") : _vm._e(),
              _vm.isCustomer && !_vm.isProxy
                ? _c(
                    "v-btn",
                    {
                      class: {
                        "disable-events": _vm.currentPage === "formlar",
                        "my-btn": _vm.isSmallScreen,
                      },
                      on: { click: _vm.gotoForms },
                    },
                    [
                      _c("span", [_vm._v("Formlar")]),
                      _c("v-icon", [
                        _vm._v("mdi-checkbox-multiple-marked-outline"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isCustomer && !_vm.isProxy
                ? _c(
                    "v-btn",
                    {
                      class: {
                        "disable-events": _vm.currentPage === "ayarlar",
                        "my-btn": _vm.isSmallScreen,
                      },
                      on: { click: _vm.gotoSettings },
                    },
                    [
                      _c("span", [_vm._v("Ayarlar")]),
                      _c("v-icon", [_vm._v("mdi-cog-outline")]),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isCustomer || _vm.isProxy
                ? _c(
                    "v-btn",
                    {
                      class: {
                        "disable-events": _vm.currentPage === "ders-programi",
                        "my-btn": _vm.isSmallScreen,
                      },
                      on: { click: _vm.gotoLessonProgram },
                    },
                    [
                      _c("span", [_vm._v("Ders Programı")]),
                      _c("v-icon", [_vm._v("mdi-calendar-clock-outline")]),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isCustomer || _vm.isProxy
                ? _c(
                    "v-btn",
                    {
                      class: {
                        "disable-events": _vm.currentPage === "sinavlar",
                        "my-btn": _vm.isSmallScreen,
                      },
                      on: { click: _vm.gotoExams },
                    },
                    [
                      _c("span", [_vm._v("Sınavlar")]),
                      _c("v-icon", [_vm._v("mdi-file-document-edit-outline")]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticStyle: { "max-height": "60px" },
        attrs: {
          href: "https://apps.apple.com/tr/app/metaceviz/id1645521965",
          target: "_blank",
          "data-v-1940ce3b": "",
        },
      },
      [
        _c("img", {
          staticStyle: { "max-width": "190px" },
          attrs: { src: "/media/logos/app-store.png" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "mx-2",
        attrs: {
          href: "https://play.google.com/store/apps/details?id=com.cp.metaceviz",
          target: "_blank",
          "data-v-1940ce3b": "",
        },
      },
      [
        _c("img", {
          staticStyle: { "max-width": "190px" },
          attrs: { src: "/media/logos/google-play.png" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }