<template>
  <div>
    <v-btn
        icon
        href="#"
        id="kt_quick_actions_toggle"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        data-original-title="Quick Actions"
        class="ml-1"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <div id="kt_quick_actions" ref="kt_quick_actions" class="offcanvas offcanvas-left p-4">
      <div
          :class="
          'offcanvas-header d-flex align-items-center justify-content-between pb-7 ' +
          iosCustomMargin
          "
          kt-hidden-height="50"
      >
        <AcademicYearChanger class="ml-2"/>
        <a
            href="#"
            class="btn   btn-sm btn-icon btn-light btn-hover-danger"
            id="kt_quick_actions_close"
        >
          <i class="ki ki-close icon-sm"></i>
        </a>
      </div>
      <div class="offcanvas-content pr-5 mr-n5">
        <v-row class="gutter-b">
          <v-col cols="6" v-if="!isCustomer || isProxy">
            <v-sheet outlined color="#8950FC" rounded="lg">
              <router-link
                  to="/odevlerim"
                  class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center"
                  style="height: 110px; border-radius: 0.75rem"
              >
                <v-icon class="mt-2" color="#8950FC" x-large>mdi-book-edit-outline</v-icon>
                <span class="d-block font-weight-bolder font-size-h6 mt-1"> ÖDEVLER </span>
              </router-link>
            </v-sheet>
          </v-col>

          <v-col cols="6" v-if="(!isCustomer || isProxy) && !isElementaryStudent">
            <v-sheet outlined color="#8950FC" rounded="lg">
              <router-link
                  to="/odev-raporu"
                  class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center"
                  style="height: 110px; border-radius: 0.75rem"
              >
                <v-icon class="mt-2" color="#8950FC" x-large>mdi-finance</v-icon>
                <span class="d-block font-weight-bolder font-size-h6 mt-1"> ÖDEV RAPORU </span>
              </router-link>
            </v-sheet>
          </v-col>

          <v-col cols="6" v-if="!isCustomer || isProxy">
            <v-sheet outlined color="#8950FC" rounded="lg">
              <router-link
                  to="/sinavlar"
                  class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center"
                  style="height: 110px; border-radius: 0.75rem"
              >
                <v-icon class="mt-2" color="#8950FC" x-large>mdi-file-document-edit-outline</v-icon>
                <span class="d-block font-weight-bolder font-size-h6 mt-1"> SINAVLAR </span>
              </router-link>
            </v-sheet>
          </v-col>

          <v-col cols="6" v-if="!isCustomer || isProxy">
            <v-sheet outlined color="#8950FC" rounded="lg">
              <router-link
                  to="/sinav-raporu"
                  class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center"
                  style="height: 110px; border-radius: 0.75rem"
              >
                <v-icon class="mt-2" color="#8950FC" x-large>mdi-chart-bar-stacked</v-icon>
                <span class="d-block font-weight-bolder font-size-h6 mt-1"> SINAV RAPORU </span>
              </router-link>
            </v-sheet>
          </v-col>

          <v-col cols="6" v-if="(!isCustomer || isProxy) && !isElementaryStudent">
            <v-sheet outlined color="#8950FC" rounded="lg">
              <router-link
                  to="/calisma-planim"
                  class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center"
                  style="height: 110px; border-radius: 0.75rem"
              >
                <v-icon class="mt-2" color="#8950FC" x-large>mdi-notebook-edit-outline</v-icon>
                <span class="d-block font-weight-bolder font-size-h6 mt-1"> ÇALIŞMA PLANI</span>
              </router-link>
            </v-sheet>
          </v-col>

          <v-col cols="6" v-if="!isCustomer || isProxy">
            <v-sheet outlined color="#8950FC" rounded="lg">
              <router-link
                  to="/pano"
                  class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center"
                  style="height: 110px; border-radius: 0.75rem"
              >
                <v-icon class="mt-2" color="#8950FC" x-large>mdi-thumb-up-outline</v-icon>
                <span class="d-block font-weight-bolder font-size-h6 mt-1"> İÇERİKLER </span>
              </router-link>
            </v-sheet>
          </v-col>

          <v-col cols="6" v-if="!isCustomer || isProxy">
            <v-sheet outlined color="#8950FC" rounded="lg">
              <router-link
                  to="/randevular"
                  class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center"
                  style="height: 110px; border-radius: 0.75rem"
              >
                <v-icon class="mt-2" color="#8950FC" x-large>mdi-briefcase-clock-outline</v-icon>
                <span class="d-block font-weight-bolder font-size-h6 mt-1"> RANDEVULAR </span>
              </router-link>
            </v-sheet>
          </v-col>

          <v-col cols="6">
            <v-sheet outlined color="#8950FC" rounded="lg">
              <router-link
                  to="/gorusmeler"
                  class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center"
                  style="height: 110px; border-radius: 0.75rem"
              >
                <v-icon class="mt-2" color="#8950FC" x-large>mdi-comment-account-outline</v-icon>
                <span class="d-block font-weight-bolder font-size-h6 mt-1"> GÖRÜŞMELER </span>
              </router-link>
            </v-sheet>
          </v-col>

          <v-col cols="6" v-if="!isCustomer || isProxy">
            <v-sheet outlined color="#8950FC" rounded="lg">
              <router-link
                  to="/ders-programi"
                  class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center"
                  style="height: 110px; border-radius: 0.75rem"
              >
                <v-icon class="mt-2" color="#8950FC" x-large>mdi-calendar-clock-outline</v-icon>
                <span class="d-block font-weight-bolder font-size-h6 mt-1"> DERS PROGRAMI </span>
              </router-link>
            </v-sheet>
          </v-col>

          <v-col cols="6">
            <v-sheet outlined color="#8950FC" rounded="lg">
              <router-link
                  to="/formlar"
                  class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center"
                  style="height: 110px; border-radius: 0.75rem"
              >
                <v-icon class="mt-2" color="#8950FC" x-large
                >mdi-checkbox-multiple-marked-outline
                </v-icon
                >
                <span class="d-block font-weight-bolder font-size-h6 mt-1"> FORMLAR </span>
              </router-link>
            </v-sheet>
          </v-col>

          <v-col cols="6" v-if="!isCustomer || isProxy">
            <v-sheet outlined color="#8950FC" rounded="lg">
              <router-link
                  to="/devamsizliklar"
                  class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center"
                  style="height: 110px; border-radius: 0.75rem"
              >
                <v-icon class="mt-2" color="#8950FC" x-large>mdi-calendar-remove-outline</v-icon>
                <span class="d-block font-weight-bolder font-size-h6 mt-1"> DEVAMSIZLIK </span>
              </router-link>
            </v-sheet>
          </v-col>

          <v-col cols="6" v-if="!isCustomer || isProxy">
            <v-sheet outlined color="#8950FC" rounded="lg">
              <router-link
                  to="/diger"
                  class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center"
                  style="height: 110px; border-radius: 0.75rem"
              >
                <v-icon class="mt-2" color="#8950FC" x-large>mdi-account-key-outline</v-icon>
                <span class="d-block font-weight-bolder font-size-h6 mt-1"> YABANCI DİL UYGULAMALARI </span>
              </router-link>
            </v-sheet>
          </v-col>
          
          <v-col cols="6" v-if="!isCustomer || isProxy">
            <v-sheet outlined color="#8950FC" rounded="lg">
              <router-link
                  to="/saglik-raporlari"
                  class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center"
                  style="height: 110px; border-radius: 0.75rem"
              >
                <v-icon class="mt-2" color="#8950FC" x-large>mdi-file-document-plus-outline</v-icon>
                <span class="d-block font-weight-bolder font-size-h6 mt-1"> SAĞLIK RAPORLARI </span>
              </router-link>
            </v-sheet>
          </v-col>

          <v-col cols="6">
            <v-sheet outlined color="#8950FC" rounded="lg">
              <router-link
                  to="/bildirimler"
                  class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center"
                  style="height: 110px; border-radius: 0.75rem"
              >
                <v-icon class="mt-2" color="#8950FC" x-large>mdi-bell-outline</v-icon>
                <span class="d-block font-weight-bolder font-size-h6 mt-1"> BİLDİRİMLER </span>
              </router-link>
            </v-sheet>
          </v-col>

          <v-col cols="6" v-if="isCustomer && !isProxy">
            <v-sheet outlined color="#8950FC" rounded="lg">
              <router-link
                  to="/qr-kod"
                  class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center"
                  style="height: 110px; border-radius: 0.75rem"
              >
                <v-icon class="mt-2" color="#8950FC" x-large>mdi-qrcode</v-icon>
                <span class="d-block font-weight-bolder font-size-h6 mt-1"> QR KOD </span>
              </router-link>
            </v-sheet>
          </v-col>

          <v-col cols="6">
            <v-sheet outlined color="#8950FC" rounded="lg">
              <router-link
                  to="/ayarlar"
                  class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center"
                  style="height: 110px; border-radius: 0.75rem"
              >
                <v-icon class="mt-2" color="#8950FC" x-large>mdi-cog-outline</v-icon>
                <span class="d-block font-weight-bolder font-size-h6 mt-1"> AYARLAR </span>
              </router-link>
            </v-sheet>
          </v-col>
          <v-col cols="12" class="my-5 text-center">
            <a
                v-if="!loading"
                data-v-1940ce3b=""
                class="my-5"
                @click="loginPdevco"
            ><img
                src="/media/logos/pdevco-logo.png"
                class="w-32 md:w-auto"
                style="max-width: 190px; max-height: 60px"
                data-v-1940ce3b=""
            /></a>
            <v-progress-circular v-else color="success" class="my-5" indeterminate></v-progress-circular>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import KTLayoutQuickActions from '@/assets/js/layout/extended/quick-actions.js'
import KTOffcanvas from '../../../../assets/js/components/offcanvas'
import AcademicYearChanger from '@/view/components/AcademicYearChanger.vue'
import {Device} from '@capacitor/device'
import ApiService from '@/core/services/api.service'

export default {
  name: 'KTQuickActions',
  props: ['isProxy', 'isCustomer'],
  components: {AcademicYearChanger},
  data() {
    return {
      iosCustomMargin: 'mt-0',
      isElementaryStudent: null,
      loading: false
    }
  },
  async mounted() {
    this.getUserFromApi()
    // Init Quick Actions Offcanvas Panel
    KTLayoutQuickActions.init(this.$refs['kt_quick_actions'])
    const device = await Device.getInfo()
    if (device.platform == 'ios') this.iosCustomMargin = 'mt-5'
  },
  watch: {
    $route() {
      new KTOffcanvas(KTLayoutQuickActions.getElement()).hide()
    }
  },
  methods: {
    getUserFromApi() {
      ApiService.setHeader()
      ApiService.get('api/Profile')
          .then((data) => {
            this.isElementaryStudent = data.data.IsElementaryStudent
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },
    loginPdevco() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post("api/App/pdevco-session-link", {})
          .then(({data}) => {
            if (data != null && data != "")
              window.open(data, "_blank");
            else
              ApiService.showErrorMessage("Pdevco üyeliğiniz bulunmuyor.")

            this.loading = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    }
  }
}
</script>
