<template>
  <div>
    <v-btn icon id="kt_quick_panel_toggle" class="mx-1">
      <v-icon color="#8950FC">mdi-account</v-icon>
    </v-btn>

    <div
      id="kt_quick_panel"
      ref="kt_quick_panel"
      class="offcanvas offcanvas-left pt-5 pb-10"
      style="overflow: hidden"
    >
      <div
        class="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5"
      >
        <ul
          class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
          role="tablist"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
              aria-selected="true"
            >
              Profil
            </a>
          </li>
          <!-- <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="2"
              data-toggle="tab"
              href="#"
              role="tab"
              aria-selected="false"
            >
              Ayarlar
            </a>
          </li> -->
        </ul>
        <div class="offcanvas-close mt-n1 pr-5">
          <a
            href="#"
            class="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_panel_close"
          >
            <i class="ki ki-close icon-xs text-muted"></i>
          </a>
        </div>
      </div>
      <div class="offcanvas-content px-10">
        <div class="tab-content">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <div
                class="container d-flex flex-column align-items-center scroll"
                id="kt_quick_panel_logs"
              >
                <div class="row">
                  <!--begin::Logo-->
                  <div
                    v-if="user && user.ProfilePicture && user.ProfilePicture.length > 0"
                    class="col-12 symbol symbol-150 symbol-light-danger"
                    id="kt_quick_user_toggle"
                  >
                    <v-img
                      :src="profilePicture"
                      max-height="140"
                      max-width="140"
                      style="border-radius: 10px"
                    />
                  </div>
                  <div
                    v-else
                    class="col-12 symbol symbol-150 symbol-light-danger"
                    id="kt_quick_user_toggle"
                  >
                    <div class="symbol-label">
                      <inline-svg alt="Logo" :src="profilePicture" class="h-75" />
                    </div>
                  </div>
                  <!--end::Logo-->
                </div>
                <h3 class="text-center mb-5">{{ user.FirstName }} {{ user.LastName }}</h3>
                <div class="row mt-5">
                  <ul class="list-unstyled">
                    <li class="mt-3" v-if="!isCustomer || isProxy">
                      <b-button
                        size="lg"
                        variant="primary"
                        style="width: 225px"
                        @click="goToProfile"
                      >
                        HESABIM
                        <b-icon class="ml-2" icon="person" aria-hidden="true"></b-icon
                      ></b-button>
                    </li>
                    <li class="mt-3">
                      <b-button
                        size="lg"
                        variant="primary"
                        style="width: 225px"
                        @click="resetPassword"
                      >
                        ŞİFRENİ DEĞİŞTİR
                        <b-icon
                          class="ml-2"
                          icon="arrow-counterclockwise"
                          aria-hidden="true"
                        ></b-icon
                      ></b-button>
                    </li>
                    <li class="mt-3">
                      <b-button size="lg" variant="primary" style="width: 225px" @click="aboutUs">
                        HAKKIMIZDA
                        <b-icon class="ml-2" icon="info-circle" aria-hidden="true"></b-icon
                      ></b-button>
                    </li>
                  </ul>
                </div>
                <div class="row mt-auto">
                  <b-button style="height: 40px" size="sm" variant="danger" @click="onLogout"
                    >ÇIKIŞ YAP<b-icon
                      class="ml-2"
                      icon="box-arrow-right"
                      aria-hidden="true"
                    ></b-icon>
                  </b-button>
                </div>
              </div>
            </b-tab>
            <b-tab>
              <div class="scroll pr-7 mr-n7" id="kt_quick_panel_settings"></div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KTLayoutQuickPanel from '@/assets/js/layout/extended/quick-panel.js'
import ApiService from '@/core/services/api.service.js'
import { LOGOUT } from '@/core/services/store/auth.module'

export default {
  name: 'KTQuickPanel',
  props: ['isProxy', 'isCustomer'],
  data() {
    return {
      tabIndex: 0,
      user: {}
    }
  },
  created() {
    this.getUserFromApi()
  },
  mounted() {
    // Init Quick Offcanvas Panel
    KTLayoutQuickPanel.init(this.$refs['kt_quick_panel'])
  },
  computed: {
    profilePicture() {
      if (this.user != null) {
        return this.user.UserType == 'Student' && this.user.ProfilePicture.length > 0
          ? this.user.ProfilePicture
          : '/media/svg/avatars/001-boy.svg'
      } else {
        return '/media/svg/avatars/001-boy.svg'
      }
    }
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]')
      const links = tab.querySelectorAll('.nav-link')
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove('active')
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute('data-tab'))

      // set current active tab
      event.target.classList.add('active')
    },

    getUserFromApi() {
      ApiService.setHeader()
      ApiService.get('api/Profile')
        .then((data) => {
          this.user = data.data
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    },

    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => (location.href = '/login'))
    },

    goToProfile() {
      document.getElementById('kt_quick_panel_close').click()
      this.$router.push({ name: 'profil-duzenle' })
    },

    resetPassword() {
      document.getElementById('kt_quick_panel_close').click()
      this.$router.push({ name: 'sifre-sifirla' })
    },

    aboutUs() {
      document.getElementById('kt_quick_panel_close').click()
      this.$router.push({ name: 'hakkimizda' })
    }
  }
}
</script>
