<template>
  <div class="aside aside-left d-flex flex-column" id="kt_aside" ref="kt_aside">
    <div
        class="aside-brand d-flex flex-column align-items-center flex-column-auto pt-5 pt-lg-13 pb-5"
    >
      <div
          v-if="user && user.ProfilePicture && user.ProfilePicture.length > 0"
          class="btn p-0 symbol symbol-60 symbol-light-primary"
          id="kt_quick_user_toggle"
      >
        <v-img
            alt="Logo"
            lazy-src=""
            :src="profilePicture"
            max-height="60"
            max-width="60"
            style="border-radius: 10px"
        />
      </div>
      <div v-else class="btn p-0 symbol symbol-60 symbol-light-primary" id="kt_quick_user_toggle">
        <div class="symbol-label">
          <inline-svg alt="Logo" :src="profilePicture" class="h-75"/>
        </div>
      </div>
      <StudentChanger v-if="isCustomer"/>
      <AcademicYearChanger class="mt-5"/>
    </div>
    <div class="aside-nav d-flex flex-column align-items-center flex-column-fluid text-center">
      <ul class="nav flex-column">
        <li class="nav-item mb-3" v-b-tooltip.hover.right="'ANA SAYFA'">
          <router-link to="/dashboard">
            <v-icon color="#8950FC" large>mdi-home-variant-outline</v-icon>
            <br/>
            <span>ANA SAYFA</span>
          </router-link>
        </li>

        <li
            class="nav-item mb-3"
            v-b-tooltip.hover.right="'ÖDEVLERİM'"
            v-if="!isCustomer || isProxy"
        >
          <router-link to="/odevlerim">
            <v-icon color="#8950FC" large>mdi-book-edit-outline</v-icon>
            <br/>
            <span>ÖDEVLERİM</span>
          </router-link>
        </li>

        <li
            class="nav-item mb-3"
            v-b-tooltip.hover.right="'ÇALIŞMA PLANIM'"
            v-if="!isCustomer || isProxy"
        >
          <router-link to="/calisma-planim">
            <v-icon color="#8950FC" large>mdi-notebook-edit-outline</v-icon>
            <br/>
            <span>ÇALIŞMA PLANI</span>
          </router-link>
        </li>

        <li
            class="nav-item mb-3"
            v-b-tooltip.hover.right="'ÖDEV RAPORU'"
            v-if="(!isCustomer || isProxy) && !user.IsElementaryStudent"
        >
          <router-link to="/odev-raporu">
            <v-icon color="#8950FC" large>mdi-finance</v-icon>
            <br/>
            <span>ÖDEV RAPORU</span>
          </router-link>
        </li>

        <li
            class="nav-item mb-3"
            v-b-tooltip.hover.right="'SINAVLAR'"
            v-if="!isCustomer || isProxy"
        >
          <router-link to="/sinavlar">
            <v-icon color="#8950FC" large>mdi-file-document-edit-outline</v-icon>
            <br/>
            <span>SINAVLAR</span>
          </router-link>
        </li>

        <li
            class="nav-item mb-3"
            v-b-tooltip.hover.right="'SINAV RAPORU'"
            v-if="!isCustomer || isProxy"
        >
          <router-link to="/sinav-raporu">
            <v-icon color="#8950FC" large>mdi-chart-bar-stacked</v-icon>
            <br/>
            <span>SINAV RAPORU</span>
          </router-link>
        </li>

        <li
            class="nav-item mb-3"
            v-b-tooltip.hover.right="'İÇERİKLER'"
            v-if="!isCustomer || isProxy"
        >
          <router-link to="/pano">
            <v-icon color="#8950FC" large>mdi-thumb-up-outline</v-icon>
            <br/>
            <span>İÇERİKLER</span>
          </router-link>
        </li>

        <li
            class="nav-item mb-3"
            v-b-tooltip.hover.right="'DERS PROGRAMIM'"
            v-if="!isCustomer || isProxy"
        >
          <router-link to="/ders-programi">
            <v-icon color="#8950FC" large>mdi-calendar-clock-outline</v-icon>
            <br/>
            <span>DERS PROGRAMI</span>
          </router-link>
        </li>

        <li
            v-if="!isCustomer || isProxy"
            class="nav-item mb-3"
            v-b-tooltip.hover.right="'RANDEVULAR'"
        >
          <router-link to="/randevular">
            <v-icon color="#8950FC" large>mdi-briefcase-clock-outline</v-icon>
            <br/>
            <span>RANDEVULAR</span>
          </router-link>
        </li>

        <li class="nav-item mb-3" v-b-tooltip.hover.right="'GÖRÜSMELER'">
          <router-link to="/gorusmeler">
            <v-icon color="#8950FC" large>mdi-comment-account-outline</v-icon>
            <br/>
            <span>GÖRÜŞMELER</span>
          </router-link>
        </li>

        <li class="nav-item mb-3" v-b-tooltip.hover.right="'FORMLAR'">
          <router-link to="/formlar">
            <v-icon color="#8950FC" large>mdi-checkbox-multiple-marked-outline</v-icon>
            <br/>
            <span>FORMLAR</span>
          </router-link>
        </li>

        <li
            class="nav-item mb-3"
            v-if="!isCustomer || isProxy"
            v-b-tooltip.hover.right="'DEVAMSIZLIK'"
        >
          <router-link to="/devamsizliklar">
            <v-icon color="#8950FC" large>mdi-calendar-remove-outline</v-icon>
            <br/>
            <span>DEVAMSIZLIKLAR</span>
          </router-link>
        </li>

        <li
            class="nav-item mb-3"
            v-if="!isCustomer || isProxy"
            v-b-tooltip.hover.right="'DEVAMSIZLIK'"
        >
          <router-link to="/diger">
            <v-icon color="#8950FC" large>mdi-account-key-outline</v-icon>
            <br/>
            <span>YABANCI DİL UYGULAMALARI</span>
          </router-link>
        </li>

        <li
            class="nav-item mb-3"
            v-b-tooltip.hover.right="'SAĞLIK RAPORLARIM'"
            v-if="!isCustomer || isProxy"
        >
          <router-link to="/saglik-raporlari">
            <v-icon color="#8950FC" large>mdi-file-document-plus-outline</v-icon>
            <br/>
            <span>SAĞLIK RAPORLARI</span>
          </router-link>
        </li>

        <li class="nav-item mb-3" v-b-tooltip.hover.right="'BİLDİRİMLER'">
          <router-link to="/bildirimler">
            <v-icon color="#8950FC" large>mdi-bell-outline</v-icon>
            <br/>
            <span>BİLDİRİMLER</span>
          </router-link>
        </li>

        <li class="nav-item mb-3" v-b-tooltip.hover.right="'QR KOD'" v-if="isCustomer && !isProxy">
          <router-link to="/qr-kod">
            <v-icon color="#8950FC" large>mdi-qrcode</v-icon>
            <br/>
            <span>QR KOD</span>
          </router-link>
        </li>

        <li class="nav-item mb-3" v-b-tooltip.hover.right="'AYARLAR'">
          <router-link to="/ayarlar">
            <v-icon color="#8950FC" large>mdi-cog-outline</v-icon>
            <br/>
            <span>AYARLAR</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="aside-footer d-flex flex-column align-items-center flex-column-auto py-8"></div>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import KTLayoutAside from '@/assets/js/layout/base/aside.js'
//import KTQuickNotifications from '@/view/layout/extras/offcanvas/QuickNotifications.vue'
//import KTQuickActions from '@/view/layout/extras/offcanvas/QuickActions.vue'
//import KTQuickPanel from '@/view/layout/extras/offcanvas/QuickPanel.vue'
import StudentChanger from '@/view/components/StudentChanger.vue'
import AcademicYearChanger from '@/view/components/AcademicYearChanger.vue'
import {mapGetters} from 'vuex'
import ApiService from '@/core/services/api.service'

export default {
  name: 'Aside-Component',
  props: ['isProxy', 'isCustomer'],
  data() {
    return {
      user: {}
    }
  },
  components: {
    StudentChanger,
    AcademicYearChanger
    // KTQuickNotifications,
    // KTQuickActions,
    // KTQuickPanel
  },
  computed: {
    ...mapGetters(['currentUser']),

    profilePicture() {
      if (this.user != null) {
        return this.user.UserType == 'Student' && this.user.ProfilePicture.length > 0
            ? this.user.ProfilePicture
            : '/media/svg/avatars/001-boy.svg'
      } else {
        return '/media/svg/avatars/001-boy.svg'
      }
    }
  },
  created() {
    this.getProfile()
  },
  mounted() {
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs['kt_aside'])
    })
  },
  methods: {
    getProfile() {
      ApiService.setHeader()
      ApiService.get('api/Profile')
          .then((data) => {
            this.user = data.data
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    }
  }
}
</script>
