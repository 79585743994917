var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    { attrs: { show: _vm.loading, rounded: "lg", opacity: "1" } },
    [
      _vm.rows.length > 0
        ? _c(
            "v-card",
            { staticClass: "mx-auto", attrs: { "max-width": "450", flat: "" } },
            [
              _c(
                "v-list",
                { attrs: { "three-line": "" } },
                [
                  _vm._l(_vm.rows, function (item, index) {
                    return [
                      _c(
                        "v-list-item",
                        { key: item.Id, staticClass: "pr-0" },
                        [
                          _c("v-list-item-content", { staticClass: "pr-2" }, [
                            _c("div", [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(item.Notification.Subject)),
                              ]),
                            ]),
                            _c("div", [
                              item.Notification.Link == null
                                ? _c("p", {
                                    staticClass:
                                      "mt-3 font-weight-light text-break",
                                    domProps: {
                                      innerHTML: _vm._s(item.Notification.Body),
                                    },
                                  })
                                : _c("a", {
                                    staticClass:
                                      "mt-3 font-weight-light text-break",
                                    domProps: {
                                      innerHTML: _vm._s(item.Notification.Body),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openLink(
                                          item.Notification.Link
                                        )
                                      },
                                    },
                                  }),
                            ]),
                            _c("div", { staticClass: "text-left text-muted" }, [
                              _c("span", { staticClass: "mt-5 font-size-sm" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("ago")(
                                      item.Notification.PlannedSendDate
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          item.Notification.Path != null
                            ? _c(
                                "v-list-item-action",
                                { staticClass: "mr-4 align-self-start" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "", text: "", small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goPath(
                                            item.Notification.Path
                                          )
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-arrow-right")])],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      index != _vm.rows.length - 1
                        ? _c("v-divider", {
                            key: index,
                            staticClass: "my-0 mx-4",
                          })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-primary",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.goMyNotifications()
                        },
                      },
                    },
                    [_vm._v("Tümünü gör")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-card",
            {
              staticClass: "mx-auto",
              staticStyle: { "background-color": "transparent" },
              attrs: { "max-width": "450", flat: "" },
            },
            [
              _c("v-card-text", { staticClass: "text-center" }, [
                _vm._v(" Bildirim yok"),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }