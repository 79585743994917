var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "80%", "max-width": "400px" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { color: "#8950FC", dark: "", small: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm.academic_year_id != null &&
                      _vm.academic_year_name != ""
                        ? _c("span", [
                            _vm._v(" " + _vm._s(_vm.academic_year_name) + " "),
                          ])
                        : _c("span", [_vm._v("Akademik Yıl")]),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5 grey lighten-2" }, [
                _vm._v(" Akademik yılı değiştiriyorsunuz "),
              ]),
              _c(
                "v-container",
                [
                  _c("c-academic-year", {
                    attrs: {
                      "hide-details": "",
                      label: "Akademik yıl seçimi",
                      clearable: "false",
                    },
                    model: {
                      value: _vm.academic_year_id,
                      callback: function ($$v) {
                        _vm.academic_year_id = $$v
                      },
                      expression: "academic_year_id",
                    },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v(" Vazgeç")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.loadingSave,
                        color: "primary",
                        text: "",
                      },
                      on: { click: _vm.change },
                    },
                    [_vm._v(" Değiştir")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }