<template>
  <div class="btn px-0 pt-1">
    <v-btn v-if="!$vuetify.breakpoint.mobile" class="mx-2 mt-2" depressed @click="getData">
      <span class="text-break text-center">{{ name }}</span>
    </v-btn>
    <v-btn v-else icon @click="getData" x-large>
      <span>Öğrenciler</span>
      <v-icon x-large>mdi-account-group-outline</v-icon>
    </v-btn>
    <v-dialog v-if="isCustomer" v-model="dialog" :max-width="$vuetify.breakpoint.mobile ? 340 : 400">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Kullanıcı Listeniz</v-card-title>

        <v-card-text>
          <div class="d-flex align-items-center my-2">
            <div class="symbol symbol-50 mr-2">
              <span class="symbol-label">
                <img
                    src="/media/users/blank.jpg"
                    class="h-120 align-self-end"
                    alt=""
                    style="width: inherit"
                />
              </span>
            </div>
            <div class="d-flex flex-column flex-grow-1">
              <a href="#" class="text-dark-75 text-hover-primary font-size-lg font-weight-bolder">{{
                  customerName
                }}</a>
            </div>
            <div class="">
              <button
                  v-show="isProxy"
                  class="btn btn-light-primary btn-bold mb-1"
                  @click="removeProxyUser"
              >
                Asıl Kullanıcıya Dön
              </button>
            </div>
          </div>

          <template v-for="student in students">
            <div :key="student.Id" class="d-flex align-items-center my-2">
              <div class="symbol symbol-50 mr-2">
                <span class="symbol-label">
                  <img
                      :src="student.ImageKey ? student.FilePublicUrl : '/media/users/blank.jpg'"
                      class="h-120 align-self-end"
                      alt=""
                      style="width: inherit"
                  />
                </span>
              </div>
              <div class="d-flex flex-column flex-grow-1">
                <a
                    href="#"
                    class="text-dark-75 text-hover-primary font-size-lg font-weight-bolder"
                >{{ student.FullName }}</a
                >
              </div>
              <div class="">
                <v-icon
                    class="mr-2"
                    @click.stop="changeUser(student)"
                    v-b-tooltip.hover.top="'Kullanıcıya Geçiş Yap'"
                >
                  mdi-account-arrow-right
                </v-icon>
              </div>
            </div>
          </template>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Kapat</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ApiService from '@/core/services/api.service.js'
import {CHANGE_TOKEN} from '@/core/services/store/auth.module'
import {mapGetters} from 'vuex'

export default {
  name: 'student-changer',
  data() {
    return {
      dialog: false,
      students: []
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['currentUser']),
    isProxy() {
      return this.currentUser.ProxyUser != null
    },
    isCustomer() {
      return this.currentUser.User.User.UserType == 'Customer'
    },
    customerName() {
      return this.currentUser.User.User.FirstName + ' ' + this.currentUser.User.User.LastName
    },
    name() {
      var result = ''
      if (this.isProxy) {
        result = this.currentUser.ProxyUser.FirstName
      } else {
        result = this.currentUser.User.User.FirstName
      }

      return result.includes(' ') && result.length > 10 ? result.split(' ')[0] : result
    }
  },
  mounted() {
    if (this.$route.query.studentId) {
      const studentId = this.$route.query.studentId

      // öğrenciye yönlendirme yapıldı, query'den silelim
      let query = Object.assign({}, this.$route.query)
      delete query.studentId
      this.$router.replace({query})

      this.getData(false, studentId)
    }
  },

  methods: {
    getData(openDialog, studentId = 0) {
      ApiService.setHeader()
      ApiService.get('api/Student/customer-student-list')
          .then((data) => {
            this.students = data.data
            if (openDialog) this.dialog = true
            if (studentId && this.students.find((x) => (x.Id = studentId))) {
              this.changeUser(this.students.find((x) => (x.Id = studentId)))
            }
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },
    changeUser(item) {
      ApiService.setHeader()
      ApiService.post('api/customerproxyuser', {
        UserId: item.Id,
        Type: 'Student'
      })
          .then(({data}) => {
            this.$store.dispatch(CHANGE_TOKEN, data)
            window.location.reload(true)
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },
    removeProxyUser() {
      ApiService.setHeader()
      ApiService.delete('api/removeproxyuser')
          .then(({data}) => {
            this.$store.dispatch(CHANGE_TOKEN, data)
            window.location.href = '/dashboard'
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    }
  }
}
</script>
