<template>
  <div class="d-flex flex-column flex-root">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile :isProxy="isProxy" :isCustomer="isCustomer"></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled"></Loader>

    <div class="d-flex flex-row flex-column-fluid page">
      <!--begin::Aside-->
      <KTAside :isProxy="isProxy" :isCustomer="isCustomer"></KTAside>
      <!--end::Aside-->

      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
        <!--begin::Header-->
        <!-- <KTHeader></KTHeader> -->
        <!--end::Header-->
        <!--begin::Content-->
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <!-- begin:: Content Head -->
          <!-- <KTSubheader
            v-if="subheaderDisplay && displaySubheaderOnDashboard"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          /> -->
          <!-- end:: Content Head -->

          <div class="d-flex flex-column-fluid">
            <div
                :class="{
                'container-fluid': contentFluid,
                container: !contentFluid
              }"
            >
              <transition name="fade-in-up">
                <router-view :isProxy="isProxy" :isCustomer="isCustomer"/>
              </transition>
            </div>
          </div>
        </div>
        <!--end::Content-->
        <!--        <KTScrollTop></KTScrollTop>-->
        <!--begin::Footer-->
        <KTFooter :isProxy="isProxy" :isCustomer="isCustomer"/>
        <!--end::Footer-->
      </div>
      <!-- <KTSidebar></KTSidebar> -->
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import HtmlClass from '@/core/services/htmlclass.service'
import KTAside from '@/view/layout/aside/Aside.vue'
//import KTSubheader from '@/view/layout/subheader/Subheader.vue'
//import KTSidebar from '@/view/layout/aside/Sidebar.vue'
import KTHeaderMobile from '@/view/layout/header/HeaderMobile'
import Loader from '@/view/layout/content/Loader.vue'
//import KTHeader from '@/view/layout/header/Header'
import KTFooter from '@/view/layout/footer/Footer'
// import KTScrollTop from '@/view/layout/extras/ScrollTop'
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from '@/core/services/store/htmlclass.module.js'
//import { LOGOUT } from '@/core/services/store/auth.module'

export default {
  name: 'Layout-page',
  components: {
    KTAside,
    //KTSubheader,
    KTHeaderMobile,
    Loader,
    //KTHeader,
    KTFooter
    // KTScrollTop
    //KTSidebar
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading')

    // initialize html element classes
    HtmlClass.init(this.layoutConfig())
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      let path = window.location.pathname + window.location.search
      this.$router.push({name: 'login', query: {redirect: path}})
      //this.$store.dispatch(LOGOUT).then(() => location.href = '/login?redirect=' + path)
      // this.$router.push({ name: 'login' })
    }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading')
    }, 2000)
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'breadcrumbs', 'pageTitle', 'layoutConfig', 'currentUser']),
    isProxy() {
      return this.currentUser.ProxyUser != null
    },
    isCustomer() {
      if (
          this.currentUser &&
          this.currentUser.User &&
          this.currentUser.User.User &&
          this.currentUser.User.User.UserType == 'Customer'
      )
        return true
      else return false
    },
    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'))
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      if (this.$route.name == 'odev-planlama') return true
      else
        return this.layoutConfig('content.width') === 'fluid'
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo')
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig('subheader.display')
    },

    /**
     * Set the subheader display on dashboard page
     * @returns {boolean}
     */
    displaySubheaderOnDashboard() {
      return this.$route.name !== 'dashboard'
    }
  }
}
</script>
