<template>
  <div
      id="kt_header_mobile"
      ref="kt_header_mobile"
      class="header-mobile header-mobile-fixed"
      v-bind:class="headerClasses"
      :style="'padding:' + topSafeArea + 'px 0px ' + topSafeArea / 2 + 'px'"
  >
    <!--begin::Logo-->
    <KTQuickActions class="ml-1" :isProxy="isProxy" :isCustomer="isCustomer"></KTQuickActions>
    <!--     <a href="/" class="text-center ml-7">-->
    <!--         <img alt="Logo" :src="logoImg" class="logo-default max-h-30px"/>-->
    <!--     </a>-->
    <!--end::Logo-->
    <!--begin::Toolbar-->
    <div class="d-flex align-items-center">
      <b-dropdown
          size="sm"
          variant="link"
          toggle-class="topbar-item text-decoration-none p-0"
          no-caret
          no-flip
          ref="notificationDropdown"
      >
        <template v-slot:button-content>
          <div :class="bellClass">
            <v-icon class="p-0" :style="bellStyle">{{ bellKind }}</v-icon>
            <span class="pulse-ring"></span>
          </div>
        </template>
        <b-dropdown-text tag="div" class="min-w-md-450px">
          <NotificationTopBar
              @shine="shineMethod"
              @dontShine="dontShineMethod"
              @closeTopBar="closeTopBar"
          ></NotificationTopBar>
        </b-dropdown-text>
      </b-dropdown>
      <KTQuickPanel :isProxy="isProxy" :isCustomer="isCustomer"></KTQuickPanel>
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import KTQuickActions from '@/view/layout/extras/offcanvas/QuickActions.vue'
import KTQuickPanel from '@/view/layout/extras/offcanvas/QuickPanel.vue'
import ApiService from '@/core/services/api.service.js'
import NotificationTopBar from '@/view/pages/notification/NotificationTopBar.vue'
import {SafeArea} from 'capacitor-plugin-safe-area'
import {Device} from '@capacitor/device'

export default {
  name: 'KTHeaderMobile',
  props: ['isProxy', 'isCustomer'],
  components: {
    KTQuickActions,
    KTQuickPanel,
    NotificationTopBar
  },
  data() {
    return {
      alternativeSettings: [],
      bellKind: 'mdi-bell',
      bellClass: 'btn btn-icon btn-clean btn-dropdown btn-lg px-0 py-0',
      bellStyle: 'color: #007bff',
      topSafeArea: 0
    }
  },
  computed: {
    ...mapGetters(['layoutConfig', 'getClasses', 'currentUser']),
    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig('self.logo')
    },
    logoImg() {
      return this.alternativeSettings.length > 0
          ? this.alternativeSettings.find((x) => x.Name === 'logo.img').Value
          : ''
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses('header_mobile')
      if (typeof classes !== 'undefined') {
        return classes.join(' ')
      }
      return null
    }
  },
  async mounted() {
    const device = await Device.getInfo()
    if (device.platform == 'ios') {
      SafeArea.getSafeAreaInsets().then(({insets}) => {
        this.topSafeArea = insets.top - 5
      })
    }

    this.getAlternativeSettingFromApi()
  },
  methods: {
    shineMethod() {
      //nexttick ile bari çalış lütfen
      this.bellClass = 'btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary'
      this.bellKind = 'mdi-bell-ring'
      this.bellStyle = 'color: red'
    },
    dontShineMethod() {
      this.bellKind = 'mdi-bell'
      this.bellClass = 'btn btn-icon btn-clean btn-dropdown btn-lg mr-1'
      this.bellStyle = 'color: #007bff'
    },
    getAlternativeSettingFromApi() {
      ApiService.setHeader()
      ApiService.get('api/Setting/alternative')
          .then((data) => {
            this.alternativeSettings = data.data
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },
    closeTopBar() {
      this.bellKind = 'mdi-bell'
      this.bellClass = 'btn btn-icon btn-clean btn-dropdown btn-lg mr-1'
      this.bellStyle = 'color: #007bff'

      this.$refs.notificationDropdown.hide()
    }
  }
}
</script>
